



































































































































































































































































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage, SfLink } from '@storefront-ui/vue';

import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'CN6thAnniversaryActivities',
  components: {
    SfLink,
  },
  setup() {
    return {
      addBasePath,
    };
  },
  mounted() {},
  created() {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line promise/catch-or-return, promise/always-return
      import('image-map-resizer').then((imageMapResize) => {
        imageMapResize.default();
      });
    }
  },
});
