var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-carousel",style:(("width: " + _vm.viewWidth))},[_c('SfCarousel',{staticClass:"carousel",attrs:{"settings":{
      type: 'carousel',
      rewind: false,
      perView: 1,
      autoplay: false,
      hoverpause: true,
      gap: 0,
      breakpoints: { 1023: { perView: 1 } },
    }}},[(_vm.isDesktop)?_vm._l((_vm.desktopSliders),function(slider,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c('SfLink',{attrs:{"link":slider.target_url}},[_c('NuxtImg',{staticClass:"desktop-only",attrs:{"preload":"","src":((slider.image_path) + "?f=webp"),"alt":slider.image_alt || 'uninned',"fetchpriority":i === 0 ? 'high' : 'low',"width":"1440","height":"375"}})],1)],1)}):_vm._l((_vm.mobileSliders),function(slider,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c('SfLink',{attrs:{"link":slider.target_url}},[_c('NuxtImg',{staticClass:"smartphone-only",attrs:{"preload":"","src":((slider.image_path) + "?f=webp"),"alt":slider.image_alt || 'uninned',"fetchpriority":i === 0 ? 'high' : 'low'}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }